<template>
  <div class="sports-game-content">
    <div class="title-box">
      <span class="title">ESPORT</span>
    </div>
    <ul class="game-type-list">
      <li
        class="game-type-list-item"
        v-for="sport in lists"
        :key="sport.id"
      >
        <div class="left-side">
          <div class="top">
            <img
               :src="systemImgHost + sport.imgUrlOne"
              class="logo"
            />
          </div>
          <div class="centerText">
            {{ sport.name }}
          </div>
          <div class="play-now">
            <div class="searchBtn" @click="_getToken(sport, 2)">Chơi ngay</div>
          </div>
        </div>
        <img
          :src="systemImgHost + sport.imgUrl"
          alt=""
          class="img"
        />
      </li>
    </ul>
  </div>
</template>
<script>
import { _getToken } from "@/core/comp/common";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      curId: "",
      curItem: {},
      newList: [], // 分割之后的新数组
      moreShow: true,
      downloadApp: "",
    };
  },
  computed: {
    lists() {
      // 二级导航
      return this.gameMenu.arr_ty || [];
    },
    ...mapGetters([
      "gameMenu", // 游戏分类
      "systemImgHost",
    ]),
  },
  mounted() {
    this.splitList();
    if (this.lists.length > 0) {
      this.curItem = this.lists[0];
      this.curId = this.lists[0].id;
    }
  },
  methods: {
    _getToken,
    switchTab(item) {
      this.curItem = item;
      this.curId = item.id;
    },
    // 数组切割 列表 每组几个
    splitList() {
      for (let i = 0; i < this.lists.length; i += 5) {
        this.newList.push(this.lists.slice(i, i + 5));
      }
    },
    moreChange() {
      this.moreShow = !this.moreShow;
    },
  },
};
</script>
<style lang="less" scoped>
.sports-game-content {
  width: 100%;
  position: relative;
  padding-top: 38px;
  .title-box {
    height: 40px;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 24px;
    ::after {
      content: "";
      width: 8px;
      height: 40px;
      background-color: #f7bd01;
      position: absolute;
      top: 50%;
      left: 0px;
      transform: translateY(-50%);
    }
    .title {
      font-size: 24px;
      font-weight: 700;
      color: #fff;
      padding-left: 26px;
      padding-right: 14px;
    }
  }
  .game-type-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    list-style: none outside none;
    margin: 0;
    padding: 0;
    .game-type-list-item {
      width: 590px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      position: relative;
      margin: 0 0 40px 0;
      padding: 0;
      .left-side {
        width: 160px;
        height: 320px;
        padding: 0 10px;
        background-color: #262626;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        box-shadow: 0px 1px 5px 0px rgb(38 38 38 / 30%);
        box-sizing: border-box;
        position: absolute;
        top: 0px;
        left: 0px;
        .top {
          height: 62px;
          display: flex;
          justify-content: center;
          align-items: center;
          .logo {
            height: 100%;
          }
        }
        .centerText {
          border-top: 1px dashed rgba(255, 255, 255, 0.3);
          width: 100%;
          color: rgba(255, 255, 255, 0.6);
          padding: 24px 0;
          line-height: 24px;
          height: 175px;
          overflow: hidden;
          text-align: justify;
        }
        .play-now {
          margin-top: 16px;
          border-top: 1px dashed rgba(255, 255, 255, 0.3);
          display: flex;
          height: 65px;
          align-items: center;
          justify-content: center;
          .searchBtn {
            cursor: pointer;
            width: 120px;
            height: 32px;
            text-align: center;
            line-height: 32px;
            font-weight: 700;
            background: linear-gradient(60deg, #fbb132, #ffda03);
            border-radius: 16px;
            transition: all 0.2s;
          }
        }
      }
      .img {
        width: 460px;
        height: 320px;
        border-radius: 8px;
        cursor: pointer;
        border: 0;
        vertical-align: middle;
        display: block;
      }
    }
  }
}
</style>