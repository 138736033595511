<!-- 电子游戏 -->
<template>
  <div class="poker-game-content">
    <div v-if="!moreGame">
      <div class="title-box">
        <span class="title">Elec</span>
      </div>
      <ul class="game-type-list">
        <li
          class="game-type-list-item"
          v-for="(item, index) in newList"
          :key="index"
        >
          <div class="left-side">
            <div class="top">
              <img :src="systemImgHost + item.imgUrlOne" class="logo" />
            </div>
            <div class="centerText">
              {{ item.name }}
            </div>
            <div class="play-now">
              <div class="searchBtn" @click="tapGame(item)">Chơi ngay</div>
            </div>
          </div>
          <img :src="systemImgHost + item.imgUrl" alt="" class="img" />
        </li>
      </ul>
    </div>
    <div v-if="moreGame">
      <div class="header">
        <div class="back" @click="goBack">
          <i class="el-icon-arrow-left"></i>
          <span>BACK</span>
        </div>
        <div class="header-title">
          <img
            :src="systemImgHost + activeGameType.imgUrlOne"
            class="game-hall-logo"
          />
          <p>{{ activeGameType.name }}</p>
        </div>
        <div class="input-wrapper">
          <el-input
            v-model="searchText"
            type="warning"
            autocomplete="off"
            placeholder="Nhập tên game và ấn vào nút tìm kiếm"
          >
            <el-button
              @click="search"
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </div>
      </div>
      <ul class="tab-sub-list">
        <li
          class="list-item"
          v-for="item in showList"
          :key="item.id"
          @click="_getToken(item, 1)"
        >
          <div class="list-item-img">
            <img :src="systemImgHost + item.pictureUrl" class="img" />
            <div class="bg">
              <div class="playnow">Chơi ngay</div>
            </div>
          </div>
          <div class="description">
            <p class="description-head">
              {{ item.name || "--" }}
            </p>
          </div>
        </li>
      </ul>
      <div class="pagination-box">
        <Pagination
          v-show="activeGame && activeTab == 1"
          layout="prev, pager, next"
          :hide-on-single-page="true"
          :current-page.sync="pages.currPage"
          :page-size="pages.pageSize"
          :total="listTotal"
          @size-change="pageChange"
          @current-change="pageChange"
        ></Pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { _getToken } from "@/core/comp/common";
import { mapGetters } from "vuex";
import { Pagination, Message } from "element-ui";
import {
  _thirdList,
  _searchGameAction,
  _recommendGameByIDAction,
} from "@/core/comp/pages/games";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      curId: "",
      loading: false,
      searchText: "", // 当前搜索的内容
      searchList: [], // 搜索结果

      activeGame: "", // 当前激活的游戏平台ID
      activeTab: 1, // 当前激活的分类 1-全部  2-热门游戏
      newList: [], // 分割之后的新数组
      pages: {
        // 分页管理
        currPage: 1,
        pageSize: 24,
      },
      moreGame: false,
      activeGameType: {},
    };
  },
  watch: {
    activeGame(val) {
      // 监听平台变化
      if (this.gameElecPages[val]) {
        // 当平台变化时发现该平台有缓存的数据 则直接展示
        this.pages = JSON.parse(JSON.stringify(this.gameElecPages[val]));
      }
    },
    // lists (val) { // 监听二级分类数据变化，当变化时默认获取第一个元素的子游戏列表
    //     if (val && val[0]) {
    //         console.log(1)
    //         this.tapGame(val[0]) // 当前页刷新时的初始化
    //     }
    // },
  },
  mounted() {
    this.splitList();
  },
  computed: {
    showList() {
      // 展示的游戏列表
      if (this.activeGame) {
        // 全部游戏列表
        if (this.activeTab == 1)
          return this.gameElecLists[this.activeGame] || [];
        // 热门游戏列表
        if (this.activeTab == 2)
          return this.gameElecRecommen[this.activeGame] || [];
      }
      return this.searchList; // 搜索结果
    },
    listTotal() {
      // 列表总数
      if (this.gameElecPages[this.activeGame])
        return this.gameElecPages[this.activeGame].total;
      return 0;
    },
    lists() {
      // 二级导航
      return this.gameMenu.arr_dz || [];
    },
    ...mapGetters([
      "isLogin",
      "gameMenu", // 游戏分类
      "gameElecLists", // 三级游戏 全部列
      "gameElecPages", // 三级游戏 全部列表的页面状态
      "gameElecRecommen", // 三级游戏 热门列
      "systemImgHost",
    ]),
  },
  methods: {
    _getToken,
    goBack(){
        this.moreGame = false;
    },
    // 数组切割 列表 每组几个
    splitList() {
      this.newList = this.lists;
      //   for (let i = 0; i < this.lists.length; i += 8) {
      //     this.newList.push(this.lists.slice(i, i + 8));
      //   }
    },
    // 搜索
    search() {
      if (!this.isLogin) return Message.warning(this.$t("tipsMessage.text1"));
      if (this.searchText === "") {
        this.activeGame = this.activeGameType.id;
        return;
      }
      this.curId = "";
      this.activeGame = "";
      this.activeTab = 1;
      console.log("---搜索", this.searchText);
      setTimeout(() => {
        this.searchList = [];
        _searchGameAction(this.searchText).then((res) => {
          if (res) this.searchList = res;
        });
      }, 0);
    },
    // 翻页
    pageChange() {
      this.activeTab = 1; // 锁定为全部游戏
      this.tapGame(this.lists.find((item) => item.id == this.activeGame));
    },
    // 点击游戏平台
    tapGame(item) {
      if (this.loading) return;
      this.moreGame = true;
      this.activeGameType = item;
      this.curId = item.id;
      this.activeTab = 1;
      console.log("---点击了", item);
      this.activeGame = item.id;
      setTimeout(() => {
        // 如果发现当前缓存的是正要请求的该页数据，则不用发送请求，直接用缓存即可，可以减少大量请求
        if (
          this.gameElecPages[this.activeGame] &&
          this.gameElecPages[this.activeGame].currPage == this.pages.currPage
        )
          return;
        this.loading = true;
        _thirdList({ item, pages: this.pages }).then(() => {
          setTimeout(() => {
            this.loading = false;
          }, 100);
        });
      }, 0);
    },
    // 获取推荐游戏
    getRecommend() {
      this.activeTab = 2; // 锁定为推荐游戏
      const item = this.lists.find((item) => item.id == this.activeGame);
      setTimeout(() => {
        console.log("---推荐", item);
        _recommendGameByIDAction(item);
      }, 0);
    },
  },
};
</script>
<style lang="less" scoped>
.poker-game-content {
  width: 100%;
  position: relative;
  padding-top: 38px;
  .title-box {
    height: 40px;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 24px;
    ::after {
      content: "";
      width: 8px;
      height: 40px;
      background-color: #f7bd01;
      position: absolute;
      top: 50%;
      left: 0px;
      transform: translateY(-50%);
    }
    .title {
      font-size: 24px;
      font-weight: 700;
      color: #fff;
      padding-left: 26px;
      padding-right: 14px;
    }
  }
  .game-type-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    list-style: none outside none;
    margin: 0;
    padding: 0;
    .game-type-list-item {
      width: 590px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      position: relative;
      margin: 0 0 40px 0;
      padding: 0;
      .left-side {
        width: 160px;
        height: 320px;
        padding: 0 10px;
        background-color: #262626;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        box-shadow: 0px 1px 5px 0px rgb(38 38 38 / 30%);
        box-sizing: border-box;
        position: absolute;
        top: 0px;
        left: 0px;
        .top {
          height: 62px;
          display: flex;
          justify-content: center;
          align-items: center;
          .logo {
            height: 100%;
          }
        }
        .centerText {
          border-top: 1px dashed rgba(255, 255, 255, 0.3);
          width: 100%;
          color: rgba(255, 255, 255, 0.6);
          padding: 24px 0;
          line-height: 24px;
          height: 175px;
          overflow: hidden;
          text-align: justify;
        }
        .play-now {
          margin-top: 16px;
          border-top: 1px dashed rgba(255, 255, 255, 0.3);
          display: flex;
          height: 65px;
          align-items: center;
          justify-content: center;
          .searchBtn {
            cursor: pointer;
            width: 120px;
            height: 32px;
            text-align: center;
            line-height: 32px;
            font-weight: 700;
            background: linear-gradient(60deg, #fbb132, #ffda03);
            border-radius: 16px;
            transition: all 0.2s;
          }
        }
      }
      .img {
        width: 460px;
        height: 320px;
        border-radius: 8px;
        cursor: pointer;
        border: 0;
        vertical-align: middle;
        display: block;
      }
    }
  }
  .header {
    display: flex;
    align-items: center;
    font-weight: 400;
    color: #ffffff;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 10px;
    .back {
      width: 85px;
      color: #c0c0c1;
      display: flex;
      align-items: center;
      border-right: 1px solid #4e4e4f;
      cursor: pointer;
      .el-icon-arrow-left {
        font-size: 25px;
      }
    }
    .header-title {
      margin-left: 20px;
      font-size: 22px;
      display: flex;
      align-items: center;

      .game-hall-logo {
        height: 62px;
        margin-right: 10px;
        border: 0;
        vertical-align: middle;
        display: block;
      }
    }
    .input-wrapper {
      transform: translate(13px, 0);
      margin-left: auto;
    }
  }
  .tab-sub-list {
    padding: 20px 0px;
    display: flex;
    flex-wrap: wrap;
    .list-item {
      width: 230px;
      margin-bottom: 10px;
      margin-right: 12px;
      &:nth-child(5n) {
        margin-right: 0px;
      }
      .list-item-img {
        width: 230px;
        height: 200px;
        position: relative;
        .img {
          width: 100%;
          height: 100%;
        }
        &:hover {
          .bg {
            display: block;
            .playnow {
              display: block;
            }
          }
        }
        .bg {
          position: absolute;
          top: 0;
          bottom: 0;
          display: none;
          left: 0px;
          right: 0px;
          background-color: rgba(243, 180, 1, 0.2);
          z-index: 10;
          .playnow {
            display: none;
            font-size: 16px;
            width: 120px;
            height: 38px;
            margin: 81px auto;
            background-color: #f3b401;
            text-align: center;
            line-height: 38px;
            border-radius: 5px;
            cursor: pointer;
          }
        }
      }
      .description {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        background: #262626;
        color: white;
        padding: 10px;
        .description-head {
          font-size: 16px;
          text-align: center;
          font-weight: bold;
          height: 26px;
          max-height: 26px;
          line-height: 26px;
          overflow: hidden;
          margin: 0 0;
        }
      }
    }
  }
}
</style>
<style>
.input-wrapper .el-input {
  height: 40px;
  width: 580px;
}
.input-wrapper .el-input .el-input__inner {
  height: 40px;
  background: #1b1b1a;
  border: none;
  border-radius: 0;
  color: #7e7d7c;
}
.input-wrapper .el-input .el-input-group__append {
  border: none;
  border-radius: 0;
  width: 50px;
  height: 32px;
  background-color: #272725;
}
.input-wrapper .el-input .el-input-group__append .el-button .el-icon-search {
  font-weight: 900;
  color: #fff;
}
</style>