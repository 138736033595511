<!-- 主页 -->
<template>
  <div>
    <!-- Banner -->
    <div class="home-banner">
      <Banner />
    </div>
    <div class="scenarios">
      <div class="scenarios-body">
        <Notice />
        <div class="nav-list-box">
          <div class="pool-wrapper">
            <el-carousel
              @change="changeCampus"
              indicator-position="none"
              arrow="always"
              style="height: 453px"
            >
              <el-carousel-item
                v-for="(item, index) in gameRecommen"
                :key="index"
              >
                <div class="pool-inner">
                  <div class="pool-img-box">
                    <img :src="systemImgHost + item.imgUrl" alt="" />
                    <div class="pool-game-logo">
                      <img
                        :src="systemImgHost + item.imgUrlOne"
                        alt=""
                        class="pool-game-logo__content"
                      />
                    </div>
                    <p class="pool-game-name">WaterWorld</p>
                  </div>
                  <div class="pool-money-box">
                    <p class="list-wrapper">
                      <span class="money-value">8</span
                      ><span class="money-value">,</span
                      ><span class="money-value">0</span
                      ><span class="money-value">0</span
                      ><span class="money-value">0</span
                      ><span class="money-value">,</span
                      ><span class="money-value">0</span
                      ><span class="money-value">0</span
                      ><span class="money-value">0</span
                      ><span class="money-value">,</span
                      ><span class="money-value">0</span
                      ><span class="money-value">0</span
                      ><span class="money-value">0</span>
                    </p>
                    <span class="money-unit">vnđ</span>
                  </div>
                  <div class="pool-timeout-box">
                    <em class="timeout-label">THỜI GIAN CÒN LẠI:</em>
                    <div class="timeout-inner">
                      <span>00</span>
                      <span class="divider">:</span>
                      <span>00</span>
                      <span class="divider">:</span>
                      <span>00</span>
                    </div>
                  </div>
                  <button
                    type="button"
                    class="el-button btn do-buy el-button--default"
                  >
                    <span>Chơi ngay</span>
                  </button>
                </div>
              </el-carousel-item>
            </el-carousel>
            <p class="pagination-wrapper">
              <span>{{ hotIndex }}</span>
            </p>
          </div>
          <ul class="nav-list">
            <li
              class="nav-list-item nav-list-item-1"
              v-for="item in navLists"
              :key="item.id"
              @click="navGameHander(item.id)"
            >
              <img :src="item.imgUrl" alt="category-image" />
            </li>
          </ul>
        </div>
        <div class="content">
          <div class="game-content">
            <Elec v-if="navActive === 'elec'" />
            <Esports v-if="navActive === 'esports'" />
            <Lottery v-if="navActive === 'lottery'" />
            <Poker v-if="navActive === 'poker'" />
            <Sports v-if="navActive === 'sports'" />
            <Live v-if="navActive === 'live'" />
          </div>
          <!-- <div class="scenarios-hot">
            <div class="scenarios-recommend sport">
              <h3 class="hot-title">
                <span>kèo hot</span>
              </h3>
              <div class="hot-body">
                <el-carousel
                  @change="changeHotCampus"
                  indicator-position="none"
                  arrow="always"
                  style="height: 231px"
                >
                  <el-carousel-item style="height: 231px">
                    <div class="sport-wrapper">
                      <div class="team-wrapper home">
                        <img
                          src="https://km1888b.com/file/picture/f39e5716-8db4-49a5-8045-a19f45642312"
                          alt=""
                          class="team-logo"
                        />
                        <p class="team-name">LA FC</p>
                      </div>
                      <div class="match-wrapper">
                        <h3 class="match-title">giải đấu</h3>
                        <h3 class="match-subTitle">NHA NGHE MY MLS</h3>
                        <p class="match-time">2022-07-09 09:00:00</p>
                        <el-button plain class="match-btn btn-play">
                          <span>cược ngay</span>
                        </el-button>
                      </div>
                      <div class="team-wrapper away">
                        <img
                          src="https://km1888b.com/file/picture/d8c1e6b3-3827-4944-941e-1188952c7eb1"
                          alt=""
                          class="team-logo"
                        />
                        <p class="team-name">LA GALAXY</p>
                      </div>
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="sport-wrapper">
                      <div class="team-wrapper home">
                        <img
                          src="https://km1888b.com/file/picture/ed183da0-b168-419d-af11-81be9bc66bb4"
                          alt=""
                          class="team-logo"
                        />
                        <p class="team-name">CINCINNATA</p>
                      </div>
                      <div class="match-wrapper">
                        <h3 class="match-title">giải đấu</h3>
                        <h3 class="match-subTitle">Nhà Nghề Mỹ MLS</h3>
                        <p class="match-time">2022-07-10 06:30:00</p>
                        <el-button type="button" class="match-btn btn-play">
                          <span>cược ngay</span>
                        </el-button>
                      </div>
                      <div class="team-wrapper away">
                        <img
                          src="https://km1888b.com/file/picture/1cef4dd7-4cf9-46c7-96aa-b0bd8199ed8e"
                          alt=""
                          class="team-logo"
                        />
                        <p class="team-name">NEW YORK RB</p>
                      </div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
              <p class="pagination-wrapper">
                <span>{{ hotIndex1 }}</span>
              </p>
            </div>
          </div> -->
          <div class="scenarios-win">
            <div class="win-container">
              <el-carousel
                indicator-position="none"
                arrow="hover"
                style="height: 187px"
              >
                <el-carousel-item style="height: 187px">
                  <div class="win-content">
                    <div class="win-item">
                      <div class="li-logo">
                        <img
                          src="https://km1888b.com/file/picture/f92b3275-8430-460a-9736-7be0e6ed24c8"
                          alt=""
                        />
                      </div>
                      <div class="li-avator">
                        <img
                          src="https://km1888b.com/file/static/img/game/cq9/185_DragonBall_英150X150.png"
                          alt=""
                        />
                      </div>
                      <span class="li-name">kin***234</span>
                      <p class="li-money">
                        <span>+</span>
                        <span>295M</span>
                        <span>VNĐ</span>
                      </p>
                    </div>
                    <div class="win-item">
                      <div class="li-logo">
                        <img
                          src="https://km1888b.com/file/picture/f0694bde-e4d1-40ec-85b0-795485dcf68c"
                          alt=""
                        />
                      </div>
                      <div class="li-avator">
                        <img
                          src="https://km1888b.com/file/static/img/game/ag/SB57_EN.png"
                          alt=""
                        />
                      </div>
                      <span class="li-name">daigia***ph</span>
                      <p class="li-money">
                        <span>+</span>
                        <span>174M</span>
                        <span>VNĐ</span>
                      </p>
                    </div>
                    <div class="win-item">
                      <div class="li-logo">
                        <img
                          src="https://km1888b.com/file/picture/7f50371b-8e8f-459d-be1e-9c3b0c3d96af"
                          alt=""
                        />
                      </div>
                      <div class="li-avator">
                        <img
                          src="https://km1888b.com/file/static/img/game/pg/majestic-treasures_500_500_en.jpg"
                          alt=""
                        />
                      </div>
                      <span class="li-name">vansy***9999</span>
                      <p class="li-money">
                        <span>+</span>
                        <span>189M</span>
                        <span>VNĐ</span>
                      </p>
                    </div>
                    <div class="win-item">
                      <div class="li-logo">
                        <img
                          src="https://km1888b.com/file/picture/6272cc7a-49af-4add-9df1-ac91e442990c"
                          alt=""
                        />
                      </div>
                      <div class="li-avator">
                        <img
                          src="https://km1888b.com/file/static/img/game/pg/rise-of-apollo_web_banner_500_500_en.jpg"
                          alt=""
                        />
                      </div>
                      <span class="li-name">maiye**2k1</span>
                      <p class="li-money">
                        <span>+</span>
                        <span>29M</span>
                        <span>VNĐ</span>
                      </p>
                    </div>
                    <div class="win-item">
                      <div class="li-logo">
                        <img
                          src="https://km1888b.com/file/picture/c8872701-54d5-4015-92fa-c85bd23d892c"
                          alt=""
                        />
                      </div>
                      <div class="li-avator">
                        <img
                          src="https://km1888b.com/file/static/img/game/mgs/108_Heroes_MF_btn_600x600_EN.png"
                          alt=""
                        />
                      </div>
                      <span class="li-name">nohu***di133</span>
                      <p class="li-money">
                        <span>+</span>
                        <span>88M</span>
                        <span>VNĐ</span>
                      </p>
                    </div>
                    <div class="win-item">
                      <div class="li-logo">
                        <img
                          src="https://km1888b.com/file/picture/77b64f78-34a0-42ca-ab1d-8c363496fd37"
                          alt=""
                        />
                      </div>
                      <div class="li-avator">
                        <img
                          src="https://km1888b.com/file/static/img/game/cq9/46.狼月moonwolf_vi.png"
                          alt=""
                        />
                      </div>
                      <span class="li-name">hero**1999</span>
                      <p class="li-money">
                        <span>+</span>
                        <span>17M</span>
                        <span>VNĐ</span>
                      </p>
                    </div>
                  </div>
                </el-carousel-item>
                <el-carousel-item>
                  <div class="win-content">
                    <div class="win-item">
                      <div class="li-logo">
                        <img
                          src="https://km1888b.com/file/picture/88d2b910-ff30-4a2d-9044-32e12e9bf713"
                          alt=""
                        />
                      </div>
                      <div class="li-avator">
                        <img
                          src="https://km1888b.com/file/static/img/game/CG2/JuFuEternalDiamond.png"
                          alt=""
                        />
                      </div>
                      <span class="li-name">saubi**9x</span>
                      <p class="li-money">
                        <span>+</span>
                        <span>21M</span>
                        <span>VNĐ</span>
                      </p>
                    </div>
                    <div class="win-item">
                      <div class="li-logo">
                        <img
                          src="https://km1888b.com/file/picture/a47ab4eb-4f90-4b40-b1cc-fb7c416274de"
                          alt=""
                        />
                      </div>
                      <div class="li-avator">
                        <img
                          src="https://km1888b.com/file/static/img/game/ag/WH42_EN.png"
                          alt=""
                        />
                      </div>
                      <span class="li-name">chienbinh***so1</span>
                      <p class="li-money">
                        <span>+</span>
                        <span>240M</span>
                        <span>VNĐ</span>
                      </p>
                    </div>
                    <div class="win-item">
                      <div class="li-logo">
                        <img
                          src="https://km1888b.com/file/picture/4dc598a3-3a24-4932-85fb-ad71156f9df3"
                          alt=""
                        />
                      </div>
                      <div class="li-avator">
                        <img
                          src="https://km1888b.com/file/static/img/game/mgs/5C600x600_mg_Wild_Catch_icon_en.png"
                          alt=""
                        />
                      </div>
                      <span class="li-name">bodi***nguoi</span>
                      <p class="li-money">
                        <span>+</span>
                        <span>266M</span>
                        <span>VNĐ</span>
                      </p>
                    </div>
                    <div class="win-item">
                      <div class="li-logo">
                        <img
                          src="https://km1888b.com/file/picture/d7237c1f-6d1f-4bbe-b293-535e23dcc306"
                          alt=""
                        />
                      </div>
                      <div class="li-avator">
                        <img
                          src="https://km1888b.com/file/picture/a2311a0c-ede2-4937-a733-b14ebb74535c"
                          alt=""
                        />
                      </div>
                      <span class="li-name">vuab***a8989</span>
                      <p class="li-money">
                        <span>+</span>
                        <span>68M</span>
                        <span>VNĐ</span>
                      </p>
                    </div>
                    <div class="win-item">
                      <div class="li-logo">
                        <img
                          src="https://km1888b.com/file/picture/27bef2de-b3bd-4623-8e4d-4a24fb8928cd"
                          alt=""
                        />
                      </div>
                      <div class="li-avator">
                        <img
                          src="https://km1888b.com/file/picture/c21b58d8-405b-4554-86ef-53c03f5b0f4f"
                          alt=""
                        />
                      </div>
                      <span class="li-name">kieu**may17</span>
                      <p class="li-money">
                        <span>+</span>
                        <span>91M</span>
                        <span>VNĐ</span>
                      </p>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
        <div class="el-dialog__wrapper" style="display: none">
          <div
            role="dialog"
            aria-modal="true"
            aria-label="dialog"
            class="el-dialog"
            style="margin-top: 15vh; width: 430px"
          >
            <div class="el-dialog__header">
              <span class="el-dialog__title"></span
              ><button
                type="button"
                aria-label="Close"
                class="el-dialog__headerbtn"
              >
                <i class="el-dialog__close el-icon el-icon-close"></i>
              </button>
            </div>
            <!----><!---->
          </div>
        </div>
        <div class="el-dialog__wrapper gameMaintenance" style="display: none">
          <div
            role="dialog"
            aria-modal="true"
            aria-label="dialog"
            class="el-dialog"
            style="margin-top: 15vh; width: 430px"
          >
            <div class="el-dialog__header">
              <span class="el-dialog__title"></span
              ><button
                type="button"
                aria-label="Close"
                class="el-dialog__headerbtn"
              >
                <i class="el-dialog__close el-icon el-icon-close"></i>
              </button>
            </div>
            <!----><!---->
          </div>
        </div>
        <div class="prompt">
          <div class="el-dialog__wrapper" style="display: none">
            <div
              role="dialog"
              aria-modal="true"
              aria-label="THÔNG BÁO QUAN TRỌNG"
              class="el-dialog"
              style="margin-top: 15vh; width: 573px"
            >
              <div class="el-dialog__header">
                <span class="el-dialog__title">THÔNG BÁO QUAN TRỌNG</span
                ><button
                  type="button"
                  aria-label="Close"
                  class="el-dialog__headerbtn"
                >
                  <i class="el-dialog__close el-icon el-icon-close"></i>
                </button>
              </div>
              <!----><!---->
            </div>
          </div>
        </div>
      </div>
      <section class="Scenarios-appdownload">
        <div class="appdownload-container">
          <div class="phone-box">
            <div class="phone-img">
              <img src="../../../assets/images/km/home-1-phone.png" alt="" />
              <img src="../../../assets/images/km/home-2-phone.png" alt="" />
              <img src="../../../assets/images/km/home-3-phone.png" alt="" />
            </div>
          </div>
          <div class="phone-qr">
            <h3 class="qr-title">Siêu ứng dụng di động</h3>
            <h2 class="qr-title2">888b aPP</h2>
            <h3 class="qr-desc">Tiện Dụng - Dễ Chơi - Dễ Thắng</h3>
            <div class="qr-body">
              <div class="qr-img">
                <div class="qr-img-icon">
                  <i class="text"></i>
                  <i class="icon"></i>
                </div>
                <div class="qrcode" id="qrcode"></div>
              </div>
              <ul class="qr-list">
                <li class="qr-li qr-li-1">
                  <i class="qr-li-icon"></i
                  ><span class="qr-li-text">đa dạng chủng loại game</span>
                </li>
                <li class="qr-li qr-li-2">
                  <i class="qr-li-icon"></i
                  ><span class="qr-li-text"
                    >thích hợp với mọi thiết bị smart phone</span
                  >
                </li>
                <li class="qr-li qr-li-3">
                  <i class="qr-li-icon"></i
                  ><span class="qr-li-text"
                    >bất cứ lúc nào bất cứ nơi đâu muốn là chơi</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- 快捷入口 -->
    <!-- <div class="home-quick">
      <Quick />
    </div> -->
    <!-- 电子模块 -->
    <!-- <div class="home-electron">
      <Electron />
    </div> -->
    <!-- 游戏分类 -->
    <!-- <div class="home-game-type">
      <GameType />
    </div> -->
    <!-- 推荐列表 -->
    <!-- <div class="home-recommend">
      <HomeRecommend />
    </div> -->
    <!-- 弹框活动 -->
  </div>
</template>

<script>
import Banner from "../../../core/components/Banner.vue";
import Notice from "../../../core/components/Notice.vue";
import Sports from "./components/Sports.vue";
import Live from "./components/Live.vue";
import Poker from "./components/Poker.vue";
import Lottery from "./components/Lottery.vue";
import Esports from "./components/Esports.vue";
import Elec from "./components/Elec.vue";
// import Quick from "../../components/Quick.vue";
// import Electron from "../../core/components/Electron.vue";
// import GameType from "../../core/components/GameType.vue";
// import HomeRecommend from "../../components/HomeRecommend.vue";
// import PopUpActivite from "../../components/pop-upActivite/index.vue";
import { _activity } from "../../../core/api/modules/promo";
import { _activeInfoAction, _getToken } from "../../../core/comp/common";
import {
  _getTime,
  _createQRcode,
  _downloadApp,
} from "../../../core/utils/utils";
import { mapGetters } from "vuex";
export default {
  components: {
    Banner,
    Notice,
    Sports,
    Live,
    Poker,
    Lottery,
    Esports,
    Elec,
    // Quick,
    // Electron,
    // GameType,
    // HomeRecommend,
    // PopUpActivite,
    // RightMenu,
  },
  data() {
    return {
      isShowLogin: false,
      isReadMore: false,
      loading: false,
      list: [],
      hotIndex: 1,
      hotIndex1: 1,
      navLists: [
        {
          imgUrl:
            "https://km1888b.com/file/static/img/gameCategory/pc/sport.png",
          id: "sports",
        },
        {
          imgUrl:
            "https://km1888b.com/file/static/img/gameCategory/pc/esport.png",
          id: "esports",
        },
        {
          imgUrl:
            "https://km1888b.com/file/static/img/gameCategory/pc/live.png",
          id: "live",
        },
        {
          imgUrl:
            "https://km1888b.com/file/static/img/gameCategory/pc/slot.png",
          id: "elec",
        },
        {
          imgUrl:
            "https://km1888b.com/file/static/img/gameCategory/pc/card.png",
          id: "poker",
        },
        {
          imgUrl:
            "https://km1888b.com/file/static/img/gameCategory/pc/lottery.png",
          id: "lottery",
        },
      ],
      navActive: "",
    };
  },
  computed: {
    ...mapGetters([
      "gameMenu", // 游戏分类
      "isLogin",
      "systemImgHost",
      "gameRecommen",
    ]),
    sportsList() {
      return this.gameMenu.arr_ty || [];
    },
    qpList() {
      return this.gameMenu.arr_qp || [];
    },
    dzList() {
      return this.gameMenu.arr_dz || [];
    },
    liveList() {
      return this.gameMenu.arr_zb || [];
    },
    cpList() {
      return this.gameMenu.arr_cp || [];
    },
  },
  mounted() {
    this.loadData();
    this.qrcodeChange();
    console.log(this.gameMenu);
  },
  methods: {
    navGameHander(id) {
      this.navActive = id;
    },
    changeCampus(val) {
      this.hotIndex = val + 1;
    },
    changeHotCampus(val) {
      this.hotIndex1 = val + 1;
    },
    qrcodeChange() {
      let url = _downloadApp();
      _createQRcode({
        id: "qrcode",
        content: url,
        width: 146,
        height: 146,
      });
    },
    goGames(item, name) {
      let id = item.id;
      this.$router.push({
        path: name,
        query: { id },
      });
    },
    _getToken,
    readMore() {
      this.isReadMore = !this.isReadMore;
    },
    // 加载数据
    loadData() {
      const params = {
        currentPage: 1,
        pageSize: 3,
        ascriptionType: "",
      };
      this.loading = true;
      _activity(params).then((res) => {
        this.loading = false;
        if (!res || res.code) return;
        res.data.content.map((a) => {
          // 设置图片链接
          a._picture = this.systemImgHost + a.picture;
          // 设置时间文本
          a._titleText = "";
          if (a.forever == 1) a._titleText = _getTime(a.validStartTime);
          if (a.forever == 0 && a.type == 0)
            a._titleText = `${_getTime(a.validStartTime)}${this.$t(
              "promoInfo.text5"
            )}${_getTime(a.validEndTime)}`;
          if (a.forever == 0 && a.type != 0)
            a._titleText = `${_getTime(a.startTime)}${this.$t(
              "promoInfo.text5"
            )}${_getTime(a.endTime)}`;
          return a;
        });
        this.list = res.data.content;
      });
    },
    // 查看活动详情
    promoInfo(item) {
      _activeInfoAction(item);
    },
  },
};
</script>

<style scoped lang="less">
.home-banner {
  width: 100%;
  height: 500px;
}
.scenarios {
  padding-top: 7px;
  overflow: hidden;
  background: #0d0d0d url(../../../assets/images/km/scenarios-bk.jpg) no-repeat
    top center / contain;
  ul {
    list-style: none outside none;
  }
  ul,
  ol,
  li,
  dl,
  dd,
  dt {
    margin: 0;
    padding: 0;
  }
  .pagination-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    bottom: 12px;
    height: 15px;
    width: 50px;
    text-align: center;
    line-height: 15px;
    font-size: 15px;
    padding: 0 0;
    margin: 0 0;
    span {
      font-size: 20px;
      color: #ffbe00;
    }
  }
  .scenarios-body {
    width: 1200px;
    margin: 0 auto;
    .nav-list-box {
      width: 100%;
      height: 453px;
      background: transparent;
      display: flex;
      justify-content: flex-end;
      .pool-wrapper {
        width: 365px;
        margin-right: 13px;
        background-color: #1c1c1c;
        position: relative;
        .pool-inner {
          height: 453px;
          background: #1b1b1b;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .pool-img-box {
            margin: 18px 0;
            width: 198px;
            height: 209px;
            overflow: hidden;
            position: relative;
            border-radius: 11px;
            .pool-game-logo {
              position: absolute;
              left: 0;
              top: 7px;
              width: 68px;
              height: 37px;
              background: rgba(0, 0, 0, 0.71);
              border-radius: 0 5px 5px 0;
              border: none;
              display: flex;
              justify-content: center;
              align-items: center;
              .pool-game-logo__content {
                width: 57px;
                height: 32px;
              }
            }
            img {
              width: 100%;
              height: 100%;
            }
          }
          .pool-money-box {
            width: 295px;
            height: 64px;
            text-align: center;
            background: rgba(72, 56, 8, 0.87);
            border: 3px solid #fbbe3e;
            border-radius: 10px;
            box-shadow: inset 0 0 10px 3px #fad74a, 0 0 10px 3px #fad74a;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            .list-wrapper {
              position: relative;
              .money-value {
                font-size: 27px;
                font-weight: bold;
                color: #ffd543;
                display: inline-block;
              }
            }
            .money-unit {
              text-transform: uppercase;
              width: 32px;
              height: 9px;
              line-height: 9px;
              font-size: 13px;
              font-weight: 500;
              color: #ffd543;
              transform: translateY(4px);
            }
          }
          .pool-timeout-box {
            margin: 13px 0 6px;
            display: flex;
            align-items: center;
            .timeout-label {
              width: 125px;
              font-size: 12px;
              font-weight: 500;
              white-space: nowrap;
              color: #ffffff;
              transform: translateY(3px);
            }
            .timeout-inner {
              display: flex;
              align-items: center;
              span {
                width: 43px;
                height: 34px;
                background: #ffffff;
                border-radius: 9px;
                line-height: 35px;
                text-align: center;
              }
              .divider {
                width: 16px;
                background: transparent;
                line-height: 34px;
                font-size: 14px;
                font-weight: 400;
                color: #ffffff;
                text-align: center;
              }
            }
          }
          .do-buy {
            width: 298px;
            height: 47px;
            border-radius: 7px;
            background: linear-gradient(-36deg, #ffbe00 0%, #ffa300 100%);
            font-size: 20px;
            font-weight: 600;
            color: #0d1230;
            text-transform: uppercase;
            cursor: pointer;
          }
        }
      }
      .nav-list {
        height: inherit;
        width: 822px;
        .nav-list-item {
          float: left;
          width: 265px;
          height: 220px;
          color: rgba(255, 255, 255, 0.6);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transform: perspective(1px) translateZ(0);
          box-shadow: 0 0 1px rgb(0 0 0 / 0%);
          position: relative;
          overflow: hidden;
          margin-right: 10px;
          margin-bottom: 13px;
          background: no-repeat center / 100%;
          &:nth-child(3n) {
            margin-right: 0px;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .content {
    width: 1200px;
    margin: 0px auto;
    .game-content {
      padding-top: 38px;
    }
    .scenarios-hot {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .scenarios-recommend {
        width: 594px;
        padding: 0;
        padding-bottom: 28px;
        position: relative;
        .pagination-wrapper {
          font-size: 0;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 39px;
        }
        .hot-title {
          margin: 0 0;
          padding: 0 0;
          height: 60px;
          line-height: 60px;
          font-size: 24px;
          font-weight: 400;
          color: #f3b401;
          text-align: center;
          text-transform: uppercase;
          span {
            box-shadow: 0 2px;
          }
        }
        .hot-body {
          position: relative;
          background: url(../../../assets/images/km/scenarios-bk-sport.png)
            no-repeat center;
          height: 231px;
          background-size: 100% 100%;
          .sport-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 27px 30px 0;
            box-sizing: border-box;
            .team-wrapper {
              display: flex;
              flex-direction: column;
              align-items: center;
              .team-logo {
                width: 106px;
                height: 106px;
                border-radius: 50%;
              }
              .team-name {
                font-size: 20px;
                font-weight: 400;
                color: #ffffff;
                margin: 7px 0 15px 0;
                text-transform: uppercase;
                white-space: nowrap;
                overflow: hidden;
              }
            }
            .match-wrapper {
              text-align: center;
              .match-title {
                margin: 0 0;
                padding: 0 0;
                font-size: 22px;
                height: 23px;
                line-height: 23px;
                font-weight: 400;
                color: #ffffff;
                text-align: center;
                text-transform: uppercase;
              }
              .match-subTitle {
                font-size: 25px;
                height: 20px;
                line-height: 20px;
                font-weight: 500;
                color: #ffffff;
                margin: 14px 0 15px 0;
                text-align: center;
                text-transform: uppercase;
                overflow: hidden;
                white-space: nowrap;
              }
              .match-time {
                margin: 0 0;
                height: 13px;
                line-height: 13px;
                font-size: 16px;
                font-weight: 400;
                color: #ffffff;
                text-align: center;
              }
              .match-btn {
                width: 217px;
                height: 48px;
                background: linear-gradient(-36deg, #ffbe00 0%, #ffa300 100%);
                border-radius: 7px;
                font-size: 20px;
                font-weight: 400;
                color: #0d1230;
                margin-top: 21px;
                border: 0px solid #dcdfe6;
                text-transform: uppercase;
                &:hover {
                  border: 1px solid #dcdfe6;
                }
              }
            }
          }
        }
      }
    }
    .scenarios-win {
      width: 100%;
      height: 233px;
      background: url(../../../assets/images/km/home-bg-win.png) no-repeat
        center / 100% 100%;
      padding: 23px 27px 23px 300px;
      box-sizing: border-box;
      margin-bottom: 0;
      position: relative;
      .win-container {
        height: 100%;
        .win-content {
          padding: 10px;
          width: 100%;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(3, 1fr);
          justify-content: space-between;
          grid-gap: 11px 48px;
          box-sizing: border-box;
          .win-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 48px;
            background: rgba(75, 55, 0, 0.68);
            border-radius: 7px;
            padding-left: 23px;
            box-shadow: 0 0 7px 0 #ffd300;
            box-sizing: border-box;
            cursor: pointer;
            img {
              box-sizing: border-box;
              border: 0;
              vertical-align: middle;
              display: block;
            }
            .li-logo {
              width: 34px;
              max-height: 22px;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                max-height: 100%;
                min-height: 100%;
              }
            }
            .li-avator {
              margin: 0 13px 0 16px;
              width: 36px;
              height: 36px;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .li-name {
              font-size: 12px;
              font-weight: 800;
              color: #ffffff;
              flex: 1;
            }
            .li-money {
              font-size: 0;
              flex: 1;
              margin: 0 0;
              padding: 0 0;
              span {
                font-size: 13px;
                font-weight: 800;
                color: #ffd300;
                &:last-child {
                  margin-left: 10px;
                  font-size: 12px;
                  font-weight: 300;
                }
              }
            }
          }
        }
      }
    }
  }
  .Scenarios-appdownload {
    background: url(../../../assets/images/km/home-appdownload-bg.png) no-repeat
      center top / cover;
    margin-top: 24px;
    .appdownload-container {
      width: 1200px;
      height: 428px;
      margin: 0 auto;
      display: flex;
      .phone-box {
        width: 295px;
        .phone-img {
          position: relative;
          height: 100%;
          img {
            border: 0;
            vertical-align: middle;
            display: block;
            position: absolute;
            bottom: 10px;
            &:nth-child(1) {
              left: -55px;
              bottom: 40px;
            }
            &:nth-child(2) {
              right: -125px;
              bottom: 40px;
            }
            &:nth-child(3) {
              left: 50px;
              height: 413px;
            }
          }
        }
      }
      .phone-qr {
        flex: 1;
        margin-left: 173px;
        padding-top: 23px;
        .qr-title {
          text-align: center;
          font-weight: 700;
          font-size: 25px;
          line-height: 25px;
          color: #ffffff;
          text-transform: uppercase;
          margin: 0 0;
        }
        .qr-title2 {
          text-align: center;
          font-weight: 900;
          font-size: 50px;
          line-height: 50px;
          margin: 15px 0;
          color: #f7bd00;
          text-transform: uppercase;
        }
        .qr-desc {
          margin-bottom: 30px;
          padding-left: 0;
          text-align: center;
          font-weight: 400;
          text-transform: uppercase;
          font-size: 17px;
          color: #f7bd00;
        }
        .qr-body {
          display: flex;
          align-items: stretch;
          .qr-img {
            width: 176px;
            padding: 10px;
            box-sizing: border-box;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid #ff9e01;
            border-radius: 10px;
            &::before {
              content: "";
              display: block;
              position: absolute;
              left: 0;
              height: 100%;
              width: 50px;
              border: 1px solid #ff9e01;
              border-right: 0px;
              border-bottom: 0px;
              border-radius: 10px 0 0 10px;
            }

            &::after {
              content: "";
              display: block;
              position: absolute;
              right: 0;
              height: 100%;
              width: 20px;
              border: 1px solid #ff9e01;
              border-left: 0px;
              border-bottom: 0px;
              border-radius: 0 10px 10px 0;
            }
            img {
              width: 100%;
              border: 0;
              vertical-align: middle;
              display: block;
            }
            .qrcode {
              width: 156px;
              height: 156px;
              padding: 5px 5px;
              background: #fff;
              box-sizing: border-box;
              img {
                width: 146px !important;
                height: 146px !important;
              }
            }
            .qr-img-icon {
              position: absolute;
              top: 0;
              transform: translateY(-80%);
              right: 22px;
              display: flex;
              align-items: flex-end;
              .text {
                width: 60.89px;
                height: 13.67px;
                background: url(../../../assets/images/km/text_icon.png)
                  no-repeat center / cover;
              }
              .icon {
                width: 42.25px;
                height: 21.13px;
                background: url(../../../assets/images/km/icon_1.png) no-repeat
                  center / cover;
              }
            }
          }
          .qr-list {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            list-style: none outside none;
            margin: 0 0 0 54px;
            padding: 0;
            .qr-li {
              margin: 0;
              padding: 0;
              font-weight: 400;
              font-size: 12px;
              text-transform: uppercase;
              color: #ffffff;
              display: flex;
              align-items: center;
              .qr-li-icon {
                width: 36.04px;
                height: 34.8px;
                background-image: url(../../../assets/images/km/qr_icon.png);
              }
              .qr-li-text {
                margin-left: 22px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style>
.pool-wrapper,
.win-container,
.hot-body .el-carousel__container {
  position: relative;
  height: 100%;
}
.pool-wrapper .el-carousel__arrow--left {
  left: 125px;
}
.pool-wrapper .el-carousel__arrow--right {
  right: 125px;
}
.pool-wrapper .el-carousel__arrow {
  padding: 0;
  margin: 0;
  height: 36px;
  width: 36px;
  cursor: pointer;
  border-radius: 50%;
  background-color: rgba(31, 45, 61, 0.11);
  color: #fff;
  position: absolute;
  display: block !important;
  bottom: 0;
  top: auto;
  z-index: 10;
  transform: none;
  text-align: center;
  font-size: 12px;
}
.pool-wrapper .el-carousel__container {
  position: relative;
  height: 100%;
}
.pool-wrapper .el-carousel__arrow i {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
}
.hot-body .el-carousel__arrow {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  height: 36px;
  width: 36px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50%;
  background-color: rgba(31, 45, 61, 0.11);
  color: #fff;
  position: absolute;
  z-index: 10;
  text-align: center;
  font-size: 12px;
  bottom: 0;
  top: auto;
  transform: none;
}

.hot-body .el-carousel__arrow i {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
}
.hot-body .el-carousel__arrow--left {
  left: 239px;
}
.hot-body .el-carousel__arrow--right {
  right: 239px;
}
.win-container .el-carousel__container {
  position: relative;
  height: 100%;
}
.win-container .el-carousel__arrow i {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
}
</style>